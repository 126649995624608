section.my-account-page {
	padding-top: $padding-medium;
	padding-bottom: $padding-medium;

	.input-group {
		margin-bottom: $padding-small;
	}

	@media (min-width: $screen-md-min) {

	}
}