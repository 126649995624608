.icon-grid-module {

	text-align: center;

	.text-block {
		margin-left: auto;
		margin-right: auto;

		h1, h2, h3 {
			margin-top: 0px;
			margin-bottom: $padding-small;

			text-transform: none;
			text-align: center;
		}
	}

	.icons {
		display: flex;
		vertical-align: middle;
		flex-wrap: wrap;

		padding-top: $padding-medium;

		text-align: center;

		.icon {
			padding-top: $padding-medium;
			padding-left:$padding-small;
			padding-right: $padding-small;
			flex: auto;

			i.fa {
				width: 125px;
				height: 125px;
				border: 1px solid $white;
				border-radius: 50%;
				text-align: center;
				line-height: 100px;
				vertical-align: middle;
				padding: 10px 0;
				font-size: 60px;
			}

			h4 {
				margin-top: $padding-small;
				margin-bottom: $padding-small;

				font-weight: bold;
			}
		}
	}

	@media (min-width: $screen-sm-min) {

		.icons {

			.icon {
				width: 30%;
				padding-top: $padding-small;
			}
		}
	}
}