header.page-header {
    margin: 0px;
    padding: $padding-small + 30px 0;
    border-bottom: 0px;

    background-color: $terracotta;
    color: $white;

    h1 {
        font-weight: 300;
    }

    &.members-area-page-header {
    	background-color: $downy;
	}
    
    @media (min-width: $screen-sm-min) {
        padding: $padding-small + 30px;

    }
}