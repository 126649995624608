.coloured-banner {
  &.module {
    padding: 0;
    position: relative;
  }

  .flex {
    display: flex;
    flex-direction: column;
    justify-items: center;
  }

  .image {
    align-self: end;
    margin: 0 auto;


    img {
      width: 100%;
    }
  }

  .inner-content {
    padding: 40px 0 40px 0;

    p {
      font-size: 18px;
      line-height: 23px;
    }
  }

  .button {
    margin-top: 35px;
  }

  @media (min-width: $screen-md-min) {
    .flex {
      flex-direction: row;
    }

    .inner-content {
      padding: 40px 0 40px 40px;
    }

    .image {
      order: 1;
      width: 100%;
    }
    .content {
      order: 2;
    }
  }
  @media (min-width: 1200px) {
    .inner-content {
      padding: 90px 0 90px 40px;
    }
  }

}
