.home {
	.modules {

		.module {

			&:first-child {

				h1, h2, h3 {
					margin-top: 0px;
					margin-bottom: 0px;
					text-transform: none;
					vertical-align: middle;
					font-weight: 300;
				}

				h1 {
					font-weight: 900;
					margin-bottom: 10px;
				}

				h2 {
					font-weight: normal;
				}

				h3 {
					font-weight: light;
				}

				strong {
					font-weight: 900;
				}
			}
		}
	}
}