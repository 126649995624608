.cards {
  display: flex;
  flex-wrap: wrap;
}

.card {
  width: 100%;
  position: relative;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: $padding-small;

  vertical-align: top;

  .card-block {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex: 1 0 auto;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: $padding-small + 10px;

    background-color: $white;

    color: $text-color;
    text-align: left;

    a {
      color: $downy !important;
      text-decoration: none;

      &:hover {
        text-decoration: underline !important;
      }
    }
  }

  .card-image {
    height: 200px;
    width: 100%;
    background-position: center;
    background-size: cover;
  }

  .card-title {
    margin-top: 0;
    margin-bottom: $padding-small;

    text-align: left;
  }

  .card-excerpt {
    margin-bottom: $padding-small;
    overflow: hidden;
    display: block;
    display: -webkit-box;

    text-align: left;
  }

  @media (min-width: $screen-sm-min) {
    width: 50%;
  }

  @media (min-width: $screen-lg-min) {
    width: 33.33%;
  }


}