.icon-grid-2-module {

	ul.icons {
		display: flex;
		flex-wrap: wrap;
		margin: 0 15px;

		list-style: none;
		-webkit-padding-start: 0px;
		-webkit-margin-before: 0px;
		-webkit-margin-after: 0px;

		li.icon {
			width: 100%;
			flex-grow: 1;
			display: flex;
			align-items: center;
			padding-top: $padding-medium;
			padding-right: $padding-small;

			a {
				flex-grow: 1;
				display: flex;
				align-items: center;
			}

			i.fa {				
				min-width: 80px;
				font-size: 60px;
			}

		}
	}

	@media (min-width: $screen-sm-min) {

		ul.icons {

			li.icon {
				width: 50%;
			}
		}
	}

	@media (min-width: $screen-md-min) {

		ul.icons {

			li.icon {
				width: 33.33%;
			}
		}
	}

}