.form-control {
    box-shadow: none;

}

textarea, input, input[type], .uneditable-input {   
    border-color: transparent;
    box-shadow: none;
    outline: 0 none;
}

textarea:focus, input:focus, input[type]:focus, .uneditable-input:focus, .form-control:focus {   
    border-color: transparent;
    box-shadow: none;
    outline: 0 none;
}

.input-group-addon {
	padding: 15px 0 15px 15px;
}

.btn {
	padding-left: 35px;
	padding-right: 35px;
}

.btn.btn-sm {
  padding-left: 15px;
  padding-right: 15px;
}

// Errors
.not-valid {
  @extend .has-error;
  border-color: $brand-danger;

  &.input-group {
    .input-group-addon {
      border-color: $brand-danger;
      border-right: none;
      background-color: #f4f4f6;

      color: $brand-danger;
    }

    input {
      border-color: $brand-danger;
      border-left: none;
      
      &:focus {
        box-shadow: none !important;
      }
    }
  }
}

.not-valid-tip {
  @extend .help-block;
  color: $brand-danger;
  text-align: left;
}

.gglcptch {
  margin-top: $padding-small;
}

// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-default;
}
