
.module.two-column-module {
  padding-top: 0px;
  padding-bottom: 0px;

  &.smaller-text {
    p {
      font-size: 18px;
      line-height: 23px;
    }
  }

  .alt-heading {
    width: 90%;
    margin: 0 auto;
    @media (min-width: $screen-md-min) {
      margin-top: 50px !important;
    }
  }

  .column {
    height: 100%;
    padding-top: $padding-medium;
    padding-bottom: 0px;
    display: inline-block;

    text-align: left;
  }

  &:nth-of-type(1) {
    .column {
      padding-top: 0px;
    }
  }

  .heading {
    padding: $padding-small 0;
    text-align: left;
  }

  .subheading {
    padding: $padding-small 0;
    text-align: left;
  }

  .text-block {
    padding: $padding-small 0;

    h4 {
      line-height: 1.5;
      font-weight: 300;
    }

    hr {
      width: 100px;
      border-top: 1px solid #000;
      margin: $padding-small 0;
    }

    ul {
      li {
        i {
          color: $downy;
        }
      }
    }
  }

  .btn {
    margin: $padding-small / 2 0;
  }

  .button-image {
    max-height: 50px;
  }

  .images {
    img {
      max-height: 50px;
      float: left;
      margin: $padding-small / 2;

      &:nth-of-type(1) {
        margin-left: 0px;
      }
    }
  }

  ul.check-list {
    width: 100%;
    padding-bottom: $padding-small + 10px;
    -webkit-padding-start: 0px;
    list-style: none;

    li {
      .fa {
        font-size: 25px;
        color: $downy;
      }

      .heading {
        padding-left: 5px;
        font-size: 20px;
        font-weight: bold;
      }

      p {
        padding-left: 30px;
      }
    }
  }

  .column.image-column {
    padding-top: $padding-small;
    padding-bottom: $padding-medium;

    &.middle {

    }

    &.right {
      img {
        width: 150%;
      }
    }

    &.left {
      img {
        width: 150%;
        margin-left: -50%;
      }
    }

    &.bottom {
      padding-top: $padding-medium;
      padding-bottom: 0px;
      display: inline-block;
      vertical-align: bottom;
      float: none;

      -webkit-align-self: flex-end;
      align-self: flex-end;
    }

    &.full {
      padding-top: 0px;
      padding-bottom: 0px;
      padding-left: 0px;
      padding-right: 0px;

      img {
        width: 100%;
        height: auto;
      }
    }

    &:before {
      overflow: visible;
    }

    img {
      width: 100%;
    }

  }

  .image-to-edge {
    padding-bottom: 0 !important;
  }

  @media (min-width: $screen-sm-min) {

    .column {
      padding-top: $padding-small;
      padding-bottom: $padding-small;
    }

    .column.image-column {
      padding-top: $padding-medium;

      &.full {
        padding-top: 0px;
        padding-bottom: 0px;

        img {
          width: 150%;
        }
      }
    }
    .inner .row {
      display: flex;
      align-items: center;
    }
  }

  @media (min-width: $screen-md-min) {
    ul.check-list {
      li {
        width: 30%;
        float: left;
        padding-left: 5px;
      }
    }
  }

}
