.sub-footer .section-1 {

	article.post {
		header {
			padding-bottom: $padding-small;

			img {
				width: 100%;
				height: auto;
			}

			.entry-title {
				margin-top: $padding-small;
				margin-top: $padding-small;

				a {
					color: $text-color;
					font-weight: normal;
				}
			}

			.updated {
				color: $terracotta;
			}
		}

		.read-more-button {
			margin-top: $padding-small;
		}
	}

	@media (min-width: $screen-md-min) {
		article.post {
			header {

				.entry-title {
				}
			}
		}
	}
}