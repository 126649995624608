.free-trial {

  h3 {
    margin: ($padding-medium * 2) 0 $padding-medium;
    font-weight: normal;
    clear: both;
  }

  .description {
    margin: $padding-large 0 $padding-medium;

    img {
      max-height: 50px;
      width: auto;
      float: left;
      margin: 10px 10px 10px 0;
    }
  }

  form {

    .form-group {
      width: 100%;
    }

    .form-control {
      margin-top: $padding-small;
      width: 100%;
    }

    input[type=submit] {
      @extend .btn;
      @extend .btn-primary;

      width: 100%;
      margin-top: $padding-small;
    }

    .wpcf7-not-valid {
      @extend .has-error;
      border-color: $brand-danger;
    }

    .wpcf7-not-valid-tip {
      @extend .help-block;
      color: $brand-danger;
    }
  }

  .sent {
    .wpcf7-validates-as-required {
      //border-color: $brand-success;
    }
  }

  .wpcf7-mail-sent-ok {
    @extend .alert;
    @extend .alert-success;
  }

  .wpcf7-validation-errors {
    @extend .alert;
    @extend .alert-danger;
  }

  .screen-reader-response {
    display: none;
  }

  .captcha-code {
    margin-top: $padding-small;
    float: right;
  }

  @media (min-width: $screen-md-min) {
    h3 {
      margin: ($padding-medium - 20px) 0;
    }
  }
}