.modules {
  @import "modules/two-column";
  @import "modules/coloured-banner";
  @import "modules/check-list";
  @import "modules/icon-grid";
  @import "modules/icon-grid-2";
  @import "modules/image-grid";
  @import "modules/cards";
  @import "modules/cta";
  @import "modules/quote";
  @import "modules/cta-alt";

  .module {
    padding-top: $padding-large;
    padding-bottom: $padding-large;

    overflow: hidden;

    .heading, .subheading {
      text-align: center;
      font-weight: 300;
    }

    .heading {
      margin-top: 0px;
    }

    .subheading {
      margin-left: auto;
      margin-right: auto;
      font-size: 20px;
    }

    p {
      font-size: 20px;
      font-weight: 300;
      line-height: 1.8;
    }
  }

  @mixin font-color($color) {
    color: $color;

    a {
      color: $color;

      &:hover {
        color: $white;
        text-decoration: none;
      }
    }

    small {
      color: $color;
    }
  }

  // Modules background colours
  .downy {
    background-color: $downy;
    @include font-color($white);
  }

  .gun-powder {
    background-color: $gun-powder;
    @include font-color($white);

  }

  .terracotta {
    background-color: $terracotta;
    @include font-color($white);
  }

}
