section.post {

  .featured-image {
    margin-bottom: $padding-medium;

    img.wp-post-image {
      width: 100%;
    }

    .carousel-caption {
      left: 0px;
      right: inherit;
      bottom: 0px;
      margin-left: 15px;
      margin-right: 15px;
      padding: $padding-small $padding-small + 10px;
      background-color: $terracotta;

      color: $white;
      text-shadow: none;
      font-size: $font-size-base + 8px;
    }

    img {
      height: inherit;
    }
  }

  .entry-content {
    padding-bottom: $padding-medium;
  }

  @media (min-width: $screen-sm-min) {

  }
}