section.login {
	padding-left: 0px;
	padding-right: 0px;
	background-image: url('../images/login-background.jpg');
	background-size: 100% 100%;
	overflow: hidden;

	.login-error {
		padding-top: $padding-medium;
		background-color: $white;
	}

	.login-form {
		padding: $padding-medium $padding-small;
		background-color: $white;

		text-align: center;

		h3 {
			font-weight: 300;
			margin-bottom: $padding-medium;
		}

		p {
			margin-bottom: $padding-medium;

			&.sign-up-message {
				margin-bottom: $padding-small;
			}
		}

		.input-group {
			margin-top: $padding-small;
		}

		.btn {
			padding-left: 80px;
			padding-right: 80px;
			margin-bottom: $padding-medium;
		}

		&.login-register-form {
			padding-top: 0px;
		}
	}
	
	.login-register-form {
		background-color: $white;
	}


	.login-image {
		padding-top: 0px;
		padding-bottom: 0px;
		padding-left: 0px;
		padding-right: 0px;

		img {
			width: 100%;
		}
	}

	.gglcptch {
		margin-bottom: $padding-small;

		div {
			margin: 0 auto;
		}
	}

	@media (min-width: $screen-lg-min) {

		.inner .row {
			display: flex;
			align-items: stretch;
		}

		.login-form {
			padding: $padding-large ($padding-large - 30px);
		}

		.login-image {
			img {
				min-height: 100%;
				width: auto;
				min-width: 150%;
			}
		}
	}
}