.case-studies {
  padding-bottom: $padding-medium;

  article {
    height: 100%;
    margin-top: $padding-medium + 5px;
    border-top: 5px solid $terracotta;

    h4 {
      padding: ($padding-small * 2) 0;

      a {
        color: $text-color;
        font-weight: normal;
      }
    }

    .entry-summary {
      padding-bottom: $padding-small;

      p {
        font-size: 15px;
        font-weight: 300;
        line-height: 1.8;
      }
    }

    .read-more-button {
      position: absolute;
      bottom: -20px;
      margin-top: $padding-small;
    }
  }

  @media (min-width: $screen-md-min) {

  }

}