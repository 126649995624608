footer {
	padding-top: $padding-medium;
	padding-bottom: $padding-medium;
	background-color: $oxford-blue;

	color: $white;
	font-size: 13px;
	font-weight: 400;

	h4 {
		margin-top: $padding-medium - 10px;
		margin-bottom: $padding-small;
	}

	.section-1 {
		h3 {
			margin-top: 0px;
		}

		h4 {
			margin-top: 0px;
		}
	}

	a {
		color: $white;

		font-size: 15px;
		line-height: 2.2;

		&:hover {
			color: $white;
		}
	}

	ul {
		list-style: none;
		-webkit-padding-start: 0px;
	}

	.social-links {
		padding-top: $padding-small;

		a {
			padding-right: $padding-small + 10px;

			i {
				font-size: 35px;
			}

			&:hover {
				color: $white;
				text-decoration: none;
			}

			&:active {
				color: $white;
				text-decoration: none;
			}
		}
	}

	.section-3 {

		.form-control {
			border-bottom-right-radius: 4px !important;
			border-bottom-left-radius: 4px !important;
			border-top-left-radius: 4px !important;
			border-top-right-radius: 4px !important;
		}

		.input-group-btn {
			padding-left: 10px;

			.btn {
				border-top-left-radius: $btn-border-radius-base !important;
				border-top-right-radius: $btn-border-radius-base !important;
				border-bottom-left-radius: $btn-border-radius-base !important;
				border-bottom-right-radius: $btn-border-radius-base !important;
			}
		}

		.ajax-loader {
			display: none;
		}
	}

	@media (min-width: $screen-sm-min) {
		h3 {
			margin-top: 0px;
		}

		h4 {
			margin-top: 0px;
		}

		a {
			font-size: inherit;
			line-height: inherit;
		}

		.social-links {
			padding-top: $padding-small;

			a {
				padding-right: $padding-small - 10px;

				i {
					font-size: 25px;
				}
			}
		}

	}
}