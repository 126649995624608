footer .section-3 {

	form {
		padding-top: $padding-small;
	}

	.screen-reader-response {
		display: none;
	}

	@media (min-width: $screen-sm-min) {

	}
}