@import url(https://fonts.googleapis.com/css?family=Raleway:100,300,400,400italic,700,900);

body {
	//-webkit-font-smoothing: antialiased;
	font-weight: 300;
}

h1, h2, h3, h4, h5, h6 {
	font-weight: bold;
	word-wrap: break-word;

	@media (min-width: $screen-md-min) {
		&.heading {
			font-weight: 300;
		}
	}
}

h1 {
	font-size: 30px;

	@media (min-width: $screen-md-min) {
		font-size: 41px;
	}
}

h4 {
	line-height: 1.8;
}

p {
	font-size: 20px;
	font-weight: 300;
	outline-width: 1.8;
}

ul {
	padding-left: 0px;
}

ul, .fa-ul {

	li {

		i.fa {
			margin-right: 15px;
			
			font-size: $font-size-base + 4px;
		}
	}
}

.weight-100 {
	font-weight: 100;
}

.tm {
	vertical-align: super; 
	font-size: 0.5em; 
	font-weight: 300;
}