.cards-module {

	.cards {
		margin-top: $padding-large;

		.card-title {
			font-weight: normal;
		}

		.card-block {
			p {
				font-size: 15px;
				font-weight: 300;
				line-height: 1.8;
			}
		}
	}

}