// Colors
$white:								#ffffff;
$black:								#000000;
$grey:								#909090;
$light-grey:						#f4f4f6;
$brand-primary:         			#de6e54;
$brand-info:						#71B9D2;
$oxford-blue:						#374456;
$downy:								#74B9D1;
$gun-powder:						#3D384F;
$terracotta:						#DE6E54;

$brand-danger:                      #d9534f;

$text-color:						$black;

// Padding
$padding-small: 					20px;
$padding-medium: 					60px;
$padding-large: 					100px;

// Typography
$font-family-sans-serif: 			'Raleway', sans-serif;
$font-size-base:					14px;
$line-height-base:					1.428571429;
$line-height-computed:				floor(($font-size-base * $line-height-base));

// Scaffolding
$font-size-base:					16px;

// Grid System
$grid-gutter-width:					30px;

// Panel
$panel-body-padding:				40px;

// Glyphicons font path
$icon-font-path:        			"../fonts/";

// Grid settings
$main-sm-columns:       			12;
$sidebar-sm-columns:    			4;

// Base Fields
$padding-base-vertical:				15px;
$padding-base-horizontal:			15px;
$padding-large-vertical:			20px;
$padding-large-horizontal:			50px;
$padding-small-vertical:			5px;
$padding-small-horizontal:			20px;

// Forms
$input-bg:							$light-grey;
$input-border:						transparent;
$input-group-addon-bg:				$light-grey;
$input-group-addon-border-color:	transparent;

$btn-border-radius-base:			50px;
$btn-border-radius-large:			30px;
$btn-border-radius-small:			30px;

// NavBar
$navbar-height:						100px;

$navbar-default-bg:					#fff; 
$navbar-default-border:				transparent;

$navbar-default-color:				$black;
$navbar-default-link-color:			$black;
$navbar-default-link-active-color:	$black;

$navbar-default-link-hover-bg:		$light-grey;
$navbar-default-link-active-bg:		transparent;

$navbar-brand-height:				60px;

// Breadcrumb
$breadcrumb-bg:						transparent;
$breadcrumb-padding-vertical:		$padding-medium;
$breadcrumb-padding-horizontal:		0px;
$breadcrumb-separator: 				">";

// Carousel
$carousel-control-color:			$text-color;
$carousel-indicator-border-color:	$text-color;
$carousel-caption-color:			$text-color;
$carousel-indicator-active-bg:		$text-color;
$carousel-control-opacity:			0;