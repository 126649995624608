.cat-tree-holder{ 
	h4{
		font-weight: 400;
	}
	.cat-tree{
		padding: 20px;
		background-color: #fff;
		border: 1px solid #ddd;
		border-radius: 4px;
		box-shadow: 0 1px 1px rgba(0,0,0,.05);
	}
	ul{
       list-style: none;
       li{
       	display: block;
       	padding: 5px 0;
       }
	}
	.downloads-category{
		.children{
			padding-left: 5px;
			li{
				a{
		            &:before{
		            	content: "-"; 
		            }
				}
			}
		}
	}
}