.news {
  padding-bottom: $padding-small;

  article.post {
    padding: $padding-medium 0 $padding-small;
    height: 100%;

    header {
      padding-bottom: $padding-small;

      img {
        width: 100%;
        height: auto;
      }

      .entry-title {
        padding-top: $padding-small;

        a {
          color: $text-color;
          font-weight: normal;
        }
      }

      .updated {
        color: $terracotta;
      }
    }

    .entry-summary {
      p {
        font-size: 15px;
        font-weight: 300;
        line-height: 1.8;
      }
    }

    .read-more-button {
      position: absolute;
      bottom: 0px;
      margin-top: $padding-small;
    }
  }

  @media (min-width: $screen-sm-min) {
    article.post {
      padding: $padding-medium 0 ($padding-small * 2);
    }
  }
}