section.team-members {
	padding-top: $padding-medium;
	padding-bottom: $padding-medium;

	.team-member {
		padding-top: $padding-small;
		padding-bottom: $padding-small;
		text-align: left;

		text-align: center;

		img {
			padding: 0 $padding-small;
			width: 100%;
			height: auto;
		}

		h3 {
				margin-top: $padding-small;
			font-weight: normal;
		}

		h5 {
			color: $terracotta;
			font-weight: normal;
		}

		p {
			padding-top: $padding-small - 15px;
		}
	}

	@media (min-width: $screen-md-min) {

		.team-member {
			text-align: left;

			h3 {
				margin-top: 0px;
			}
		}

	}
}