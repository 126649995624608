.quote-module {
	text-align: center;

	.quote-text {
		font-weight: bold;
	}

	.source {
		font-weight: 200;
		line-height: 1.7;

		small {
			font-weight: 200;
			font-size: $font-size-base - 3px;
		}
	}

	@media (min-width: $screen-md-min) {

	}


}