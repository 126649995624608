.videos-page {
  padding-top: $padding-medium;
  padding-bottom: $padding-medium;

  .intro {
    padding-bottom: $padding-medium;
  }

  .video {
    padding-top: $padding-small;
    padding-bottom: $padding-small;

    display: table;

    .embed {
      iframe {
        width: 100%;
        height: 300px;
      }
    }

    .description {
      display: table-cell;
      vertical-align: middle;
      height: 300px;
    }
  }
}
