.sub-footer {
	padding-top: $padding-medium;
	padding-bottom: $padding-medium;

	.widgets {
		display: flex;
  		flex-wrap: wrap;	

		.widget {
			padding-bottom: $padding-small + 10px;
		}
  	}

	h4 {
		margin-top: $padding-medium;
		margin-bottom: $padding-small;
	}

	a {
		color: $white;

		&:hover {
			color: $white;
		}
	}

	p {
		font-size: 12px;
		font-weight: 400;
		line-height: 25px;
	}

	@media (min-width: $screen-md-min) {

		h4 {
			margin-top: 0px;
		}

		.btn {
		    position: absolute;
		    bottom: 0px;
		}

		// Fixes float issue in Safari
	  	.col-md-4 {
	  		width: 33.3%;
	  	}
	  	
	}
}