.check-list-wrapper {
  padding: 40px 0;
  overflow: hidden;

  p {
    font-size: 18px;
    line-height: 23px;
  }

  .content-left {
    margin-bottom: 50px;

    p {
      font-size: 18px;
      line-height: 23px;
    }

    h2 {
      margin-bottom: 20px;
    }
  }


  .check-list {
    ul {
      margin-left: 40px;

      li {
        list-style: none;
        margin-bottom: 20px;
        font-size: 18px;
        line-height: 23px;
        position: relative;

        &:before {
          content: '';
          background: url('../images/tick.svg');
          height: 20px;
          width: 30px;
          position: absolute;
          left: -45px;
          background-repeat: no-repeat;
          background-position: center;
          object-fit: contain;
          background-size: 25px;
        }
      }
    }
  }


  @media (min-width: $screen-md-min) {
    padding: 100px 0;
    .grid {
      display: grid;
      grid-template-columns: 40% 60%;
    }
    .content-left {
      margin-bottom: 0;
    }
    .check-list {
      margin-left: 50px;

      ul {
        margin-left: 0;
      }
    }
  }
  @media (min-width: $screen-lg-min) {
    .check-list {
      margin-left: 100px;
    }
  }
}

