.image-grid-module {

	.subheading {
		margin-top: 0px;
		margin-bottom: $padding-small;

		color: $grey;
		text-transform: none;
		text-align: center;
		font-weight: normal;
	}

	ul.images {
		width: 100%;
		display: flex;
		flex-wrap: wrap;

		list-style: none;
		-webkit-padding-start: 0px;
		-webkit-margin-before: 0px;
		-webkit-margin-after: 0px;

		li.image {
			width: 100%;
			flex-grow: 1;
			display: flex;
 		 	justify-content: center;
			align-items: center;
			padding-top: $padding-medium;
			padding-left: $padding-small;
			padding-right: $padding-small;

			img {
				max-height: 100px;
				max-width: 200px;
			}
		}
	}

	&.terracotta, &.downy, &.gun-powder {
		.subheading {
			color: $white;
		}

		ul.logos {
			li.image {
				img {
					filter: brightness(0) invert(1);
				}
			}
		}
	}

	@media (min-width: $screen-sm-min) {

		ul.images {

			li.image {
				width: 50%;
			}
		}
	}

	@media (min-width: $screen-md-min) {

		ul.images {

			li.image {
				width: 25%;
			}
		}
	}

}