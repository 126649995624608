nav.navbar {
  font-size: 14px;
  .navbar-header {

    .navbar-brand {
      padding: 30px 0px 30px 15px;

      font-size: 12px;

      img {
        height: 40px;
        width: auto;

        vertical-align: middle;
        display: inline-block;
      }

      .navbar-logo {
        vertical-align: middle;
        display: inline-block;

        padding-left: 5px;

        span {
          display: block;

          &.site-title {
            color: #000;
            font-weight: bold;
          }

          &.site-description {
            font-style: italic;
          }
        }
      }
    }
  }

  .nav {
    li {
      a {
        padding-left: 30px;
        padding-right: 30px;
      }

      &.active {

        a {
          font-weight: bold;
        }

        ul li a {
          font-weight: normal;
        }
      }

      .caret {
        display: none;
      }
    }

    .dropdown-menu {
      left: 0;
      right: auto;
      border: 0px;
      border-radius: 0px;
      a {
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 20px;
        padding-right: 20px;

        font-size: 14px;
        color: $navbar-default-link-color;
      }

      .active a {
        background-color: transparent;
        color: $black;
      }
    }
  }

  .btn-members-area {
    margin-left: 20px;
  }

  .navbar-toggle {
    border-color: transparent;
  }

  .dropdown:hover .dropdown-menu {
    display: block;
  }

  @media (min-width: $screen-sm) {

    .navbar-header {

      .navbar-brand {
        padding: (($navbar-height - $navbar-brand-height) / 2) 15px;
        font-size: 14px;

        img {
          height: $navbar-brand-height;
        }
      }
    }

    .nav {
      li {
        a {
          padding-left: 10px;
          padding-right: 10px;
        }
      }
    }

  }
}

nav.navbar-users {
  min-height: 0px;
  margin-bottom: 0px;
  background-color: darken($downy, 20);
  color: $white;

  span {
    position: absolute;
    padding: 15px 10px;
    font-size: 14px;
  }

  .nav {
    padding: 0 10px;

    li {
      a {
        padding: 15px 10px;

        color: $white;

        &:hover {
          background-color: darken(darken($downy, 20), 5);
          color: $white;
        }
      }

      &.active a {
        color: $white;

        &:hover {
          background-color: darken(darken($downy, 20), 5);
          color: $white;
        }
      }
    }
  }
}

nav.mm-menu {
  background-color: $oxford-blue;
  color: $white;

  .mm-list > li > a {
    padding: 20px 10px 20px 20px;
  }

  .mm-list > li > a.mm-subclose {
    padding: 35px 10px 10px 20px;
  }

  span.caret {
    display: none;
  }

  @media (min-width: $screen-lg) {
    display: none;
  }
}

// Set burger menu at large display size
@media (max-width: $screen-lg) {
  .navbar-header {
    float: none;
  }
  .navbar-toggle {
    display: block;
  }
  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  }
  .navbar-collapse.collapse {
    display: none !important;
  }
  .navbar-nav {
    float: none !important;
    margin: 7.5px -15px;
  }
  .navbar-nav > li {
    float: none;
  }
  .navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .navbar-text {
    float: none;
    margin: 15px 0;
  }
  /* since 3.1.0 */
  .navbar-collapse.collapse.in {
    display: block !important;
  }
  .collapsing {
    overflow: hidden !important;
  }
}

.mm-opened {
  .page-wrapper {
    opacity: 0.2;
  }
}

.page-wrapper {
  -webkit-transition: opacity .4s ease;
  transition: opacity .4s ease;
}
