section.contact-us {

	h3 {
		margin: ($padding-medium - 20px) 0;
		font-weight: normal;
	}

	.acf-map {
		width: 100%;
		height: 300px;
		margin-bottom: $padding-medium - 20px;
	}

	.acf-map img {
		max-width: inherit !important;
	}

	form {
		input[type=submit] {
			@extend .btn;
   			@extend .btn-primary;

   			width: 100%;
   			margin-top: $padding-small;
		}

		.wpcf7-not-valid {
			@extend .has-error;
			border-color: $brand-danger;
		}

		.wpcf7-not-valid-tip {
			@extend .help-block;
			color: $brand-danger;
		}
	}

	.sent {
		.wpcf7-validates-as-required {
			//border-color: $brand-success;
		}
	}

	.wpcf7-mail-sent-ok {
		@extend .alert;
		@extend .alert-success;
	}

	.wpcf7-validation-errors {
		@extend .alert;
		@extend .alert-danger;
	}

	.screen-reader-response {
		display: none;
	}
}