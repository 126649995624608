.cta-module {
	text-align: center;

	.subheading {
		margin: $padding-medium 0;
	}

	.btn {
		margin: $padding-small 0;
	}

	.images {

		img {
			max-height: 50px;
			margin: $padding-small / 2 ;

			text-align: center;

			&:nth-of-type(1){
				margin-left: 0px;
			}
		}
	}

	@media (min-width: $screen-md-min) {

	}


}