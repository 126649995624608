.downloads {
	padding-top: $padding-medium;
	padding-bottom: $padding-medium;
	background-color: $light-grey;

	$download-button-width: 180px;

	h4.file-name {
		font-weight: normal;
	}

	.file-info {
		width: 100%;
	}

	ul.file-data {
		height: 120px;
		width: 100%;
		margin-top: $padding-small;

		list-style: none;
		-webkit-padding-start: 0px;

		li {
			float: left;
			margin-right: $padding-small;

			i {
				margin-right: 5px;
				color: $downy;
			}
		}

	}

	.file-download {
		width: 100%;
		min-width: $download-button-width;

	}

	@media (min-width: $screen-md-min) {

		.panel-body {
			display: flex;
		}

		ul.file-data {
			height: auto;
			margin-top: 0px;
		}

		.file-download {
			display: -webkit-flex;
			display: flex;
			-webkit-flex: 1 0 0;
			flex: 1 0 0;

			justify-content: flex-end;
			flex-direction: row;
			align-items: center;

			.btn {
				margin-top: 0px;
			}
		}


	}
}
