.client-logos {
	padding-top: $padding-medium;
	padding-bottom: $padding-medium;

	.subheading {
		margin-top: 0px;

		color: $gun-powder;
		text-transform: none;
		text-align: center;
		font-weight: normal;
	}

	ul.images {
		width: 100%;
		display: flex;
		flex-wrap: wrap;

		list-style: none;
		-webkit-padding-start: 0px;
		-webkit-margin-before: 0px;
		-webkit-margin-after: 0px;
		padding-top: $padding-medium;
		padding-bottom: $padding-medium + 10px;

		li.image {
			height: 150px;
			width: 50%;
			flex-grow: 1;
			display: flex;
			justify-content: center;
			align-items: center;

			img {
				max-height: 150px;
				height: auto;
				max-width: 200px;
				width: auto;
				padding: $padding-small;
			}
		}
	}

	.carousel-indicators {
		li {
			width: 12px;
			height: 12px;
			margin: 5px;
			border: 0px;
			background-color: lighten($grey, 0.8);

			&.active {
				border: 0px;
				background-color: $gun-powder;
			}
		}
	}


	@media (min-width: $screen-md-min) {

		ul.images {

			li.image {
				width: 20%;
			}
		}
	}

}

.client-names {
	padding-top: $padding-medium;
	padding-bottom: $padding-medium;

	background-color: $downy;
	color: $white;

	.name {
		line-height: 30px;
	}

	a {
		color: $white;
		&:hover {
			color: $white;
		}
	}

}
