.cta-alt-module {

  .heading {
    font-weight: 700 !important;
    font-size: 30px;
    line-height: 36px;
  }

  .content {
    padding: 40px 0;

    p {
      font-size: 18px;
      line-height: 23px;

      a {
        color: $brand-primary;

        &:hover {
          text-decoration: underline;
        }
      }
    }

  }


  .text-bellow-button {
    margin: 40px 0;
  }

  .images {
    display: flex;
    justify-content: center;
    gap: 20px;

    img {
      max-height: 50px;
    }
  }

  @media (min-width: $screen-md-min) {
    .heading {
      width: 80%;
      margin: 0 auto;
    }
  }
}
